import Dialog from 'quasar/src/plugins/Dialog.js';;
import { supabase } from '../../../supabase'
// const enterprise = localStorage.getItem('enterprise');

export async function getDataWithoutFilters(_, infos) {
  // console.log('getWithout', enterprise);
  
  try {
    let { data, error, status } = await supabase
    .from(infos.table)
      .select(infos.fields)
      .neq('DELETED', '*')
      .match({'GE_ENTERPRISE': infos.enterprise})
      
      if(error && status !== 406) throw error;
      
    if(data) {
      return data;
    } else {
      throw new Error('Não foi possivel encontrar dados.');
    }
  } catch (err) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: err.message || err.error_description
    });
  }
}

export async function getDataWithFilters(_, infos) {
  // console.log('getWith', enterprise);
  
  try {
    let { data, error, status } = await supabase
      .from(infos.table)
      .select(infos.fields)
      .match({'GE_ENTERPRISE': infos.enterprise})
      .like(infos.like.key, infos.like.value)
      .match(infos.filters)
      .not('DELETED', 'eq', '*');

    if(error && status !== 406) throw error;
  
    if(data) {
      return data;
    } else {
      throw new Error('Não foi possivel encontrar dados.');
    }
  } catch (err) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: err.message || err.error_description
    });
  }
}

export async function insertData(_, infos) {
  try {
    const { data, error } = await supabase
      .from(infos.table)
      .insert(infos.fields)
      .single()

      if(data) return data;
      if(error) throw error;
  } catch (error) {
    console.error(error);
  }
}

export async function updateData(_, infos) {
  try {
    const { data, error } = await supabase
      .from(infos.table)
      .update(infos.fields)
      .match(infos.match)

      if(error) throw error;
      if(data) return data;

  } catch (error) {
    console.error(error);
  }
}