import { createRouter, createWebHistory } from 'vue-router'
import { supabase } from '../supabase'

const routes = [

  //~~> ROTAS GESTÃO ESPORTES 
  {
    path: '/',
    name: 'Login',
    children: {
      sistema: false,
      withDrawer: false
    },
    component: () => import('../views/Login')
  },
  {
    path: '/presale',
    name: 'Orçamentos',
    children: {
      sistema: true,
      withDrawer: true
    },
    component: () => import('../views/PreSale')
  },
  {
    path: '/settings',
    name: 'Painel Admin',
    children: {
      sistema: true,
      withDrawer: true
    },
    component: () => import('../views/Settings')
  },
  {
    path: '/panelADM',
    name: 'Super Administrativo',
    children: {
      sistema: true,
      withDrawer: true
    },
    component: () => import('../views/PanelADM')
  },
  {
    path: '/:slug',
    name: 'Catálogo',
    children: {
      sistema: false,
      withDrawer: false
    },
    component: () => import('../views')
  },
  {
    path: '/404',
    name: 'Not Found',
    children: {
      sistema: false,
      withDrawer: false
    },
    component: () => import('../views/404')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = supabase.auth.user();
  const currentRoute = to.name

  if(currentRoute === undefined) {
    next('/404')
  } else if(
    user === null &&
    currentRoute !== 'Login' &&
    currentRoute !== 'Catálogo' &&
    currentRoute !== 'Not Found' ) {
    next('/');
  } else {
    next()
  }
})

export default router
