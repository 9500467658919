import axios from 'axios';
import Dialog from 'quasar/src/plugins/Dialog.js';;
import { supabase } from '../../../supabase'

export async function getTOPVendas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('top3_vendas', {
      enterprise: infos.enterprise, 
      league: infos.league, 
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: err.message || err.error_description
    });
  }
}

export async function getDespesas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('total_despesas', {
      enterprise: infos.enterprise, 
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: err.message || err.error_description
    });
  }
}

export async function getReceitas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('total_vendas', {
      enterprise: infos.enterprise,
      league: infos.league,
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: err.message || err.error_description
    });
  }
}

export async function rpc(_, infos) {
  try {
    let { data, error, status } = await supabase
      .rpc(infos.function, {
        enterprise: infos.enterprise,
        league: infos.league,
        date_start: infos.date_start,
        date_end: infos.date_end,
    });

    if(error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.');
    }
  } catch (e) {
    Dialog.create({
      title: 'Gestão Esportes',
      message: e.message || e.error_description
    });
  }
}

export async function burnDatas(_, infos) {
  try {
    const items = []
    infos.items.forEach(element => {
      delete element.newProduct
      items.push(element)
    })

    var folder;
    switch (infos.folder) {
      case 'Babybody':
        folder = { 'babybody': items }
        break;
      case 'Camisas NFL':
        folder = { 'camisasnfl': infos.items }
        break;
      case 'Casual':
        folder = { 'casual': infos.items }  
        break;
      case 'Chuteiras/Campo':
        folder = { 'chuteirascampo': infos.items }
        break;
      case 'Chuteiras/Salao':
        folder = { 'chuteirassalao': infos.items }
        break;
      case 'Chuteiras/Society':
        folder = { 'chuteirassociety': infos.items }
        break;
      case 'Chuteiras/Trava Mista':
        folder = { 'chuteirastravamista': infos.items }
        break;
      case 'Conjuntos/Passeio':
        folder = { 'conjuntospasseio': infos.items }
        break;
      case 'Conjuntos/Treino':
        folder = { 'conjuntostreino': infos.items }
        break;
      case 'Corta Vento':
        folder = { 'cortavento': infos.items }
        break;
      case 'Femininas':
        folder = { 'femininas': infos.items }
        break;
      case 'Formula 1':
        folder = { 'formula1': infos.items }
        break;
      case 'Kit Infantil':
        folder = { 'kitinfantil': infos.items }
        break;
      case 'Luvas de Goleiro':
        folder = { 'luvasdegoleiro': infos.items }
        break;
      case 'Meias':
        folder = { 'meias': infos.items }
        break;
      case 'Moto GP':
        folder = { 'motogp': infos.items }
        break;
      case 'Polo':
        folder = { 'polo': infos.items }
        break;
      case 'Regatas Futebol':
        folder = { 'regatasfutebol': infos.items }
        break;
      case 'Regatas NBA':
        folder = { 'regatasnba': infos.items }
        break;
      case 'Retro':
        folder = { 'retro': infos.items }
        break;
      case 'Short/Jogo':
        folder = { 'shortjogo': infos.items }
        break;
      case 'Short/NBA':
        folder = { 'shortnba': infos.items }
        break;
      case 'Short/Treino':
        folder = { 'shorttreino': infos.items }
        break;
      case 'Tenis/Adidas':
        folder = { 'tenisadidas': infos.items }
        break;
      case 'Tenis/Balenciaga':
        folder = { 'tenisbalenciaga': infos.items }
        break;
      case 'Tenis/Gucci':
        folder = { 'tenisgucci': infos.items }
        break;
      case 'Tenis/New Balance':
        folder = { 'tenisnewbalance': infos.items }
        break;
      case 'Tenis/Nike':
        folder = { 'tenisnike': infos.items }
        break;
      case 'Versao Jogador/Brasileirao':
        folder = { 'versaojogadorbrasileirao': infos.items }
        break;
      case 'Versao Jogador/Bundesliga':
        folder = { 'versaojogadorbundesliga': infos.items }
        break;
      case 'Versao Jogador/La Liga':
        folder = { 'versaojogadorlaliga': infos.items }
        break;
      case 'Versao Jogador/Liga MX':
        folder = { 'versaojogadorligamx': infos.items }
        break;
      case 'Versao Jogador/Ligue 1':
        folder = { 'versaojogadorligue1': infos.items }
        break;
      case 'Versao Jogador/MLS':
        folder = { 'versaojogadormls': infos.items }
        break;
      case 'Versao Jogador/Outras Ligas':
        folder = { 'versaojogadoroutrasligas': infos.items }
        break;
      case 'Versao Jogador/Premier League':
        folder = { 'versaojogadorpremierleague': infos.items }
        break;
      case 'Versao Jogador/SAF':
        folder = { 'versaojogadorsaf': infos.items }
        break;
      case 'Versao Jogador/Selecoes':
        folder = { 'versaojogadorselecoes': infos.items }
        break;
      case 'Versao Jogador/Serie A':
        folder = { 'versaojogadorseriea': infos.items }
        break;
      case 'Versao Torcedor/Brasileirao':
        folder = { 'versaotorcedorbrasileirao': infos.items }
        break;
      case 'Versao Torcedor/Bundesliga':
        folder = { 'versaotorcedorbundesliga': infos.items }
        break;
      case 'Versao Torcedor/La Liga':
        folder = { 'versaotorcedorlaliga': infos.items }
        break;
      case 'Versao Torcedor/Liga MX':
        folder = { 'versaotorcedorligamx': infos.items }
        break;
      case 'Versao Torcedor/Ligue 1':
        folder = { 'versaotorcedorligue1': infos.items }
        break;
      case 'Versao Torcedor/MLS':
        folder = { 'versaotorcedormls': infos.items }
        break;
      case 'Versao Torcedor/Outras Ligas':
        folder = { 'versaotorcedoroutrasligas': infos.items }
        break;
      case 'Versao Torcedor/Premier League':
        folder = { 'versaotorcedorpremierleague': infos.items }
        break;
      case 'Versao Torcedor/SAF':
        folder = { 'versaotorcedorsaf': infos.items }
        break;
      case 'Versao Torcedor/Selecoes':
        folder = { 'versaotorcedorselecoes': infos.items }
        break;
      case 'Versao Torcedor/Serie A':
        folder = { 'versaotorcedorseriea': infos.items }
        break;
      default:
        break;
    }

    const { data, error } = await supabase
    .from('GE_SETTINGS')
      .update(folder)
        .match({
          'GE_ENTERPRISE': infos.enterprise
        })

    if(error) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível gravar dados.');
    }
  } catch (e) {
    Dialog.create({
      title: 'Fature Catálogo',
      message: e.message || e.error_description
    });
  }
}

export async function setImagesByMenu(_, infos) {
  const { opt, data } = infos
  var setImages = [];

  switch (opt) {
    case 'Babybody':
      data[0].babybody.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Camisas NFL':
      data[0].camisasnfl.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Casual':
      data[0].casual.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Chuteiras/Campo':
      data[0].chuteirascampo.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Chuteiras/Salao':
      data[0].chuteirassalao.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Chuteiras/Society':
      data[0].chuteirassociety.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Chuteiras/Trava Mista':
      data[0].chuteirastravamista.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Conjuntos/Passeio':
      data[0].conjuntospasseio.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Conjuntos/Treino':
      data[0].conjuntostreino.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Corta Vento':
      data[0].cortavento.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Femininas':
      data[0].femininas.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Formula 1':
      data[0].formula1.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Kit Infantil':
      data[0].kitinfantil.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Luvas de Goleiro':
      data[0].luvasdegoleiro.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Meias':
      data[0].meias.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Moto GP':
      data[0].motogp.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Polo':
      data[0].polo.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Regatas Futebol':
      data[0].regatasfutebol.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Regatas NBA':
      data[0].regatasnba.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Retro':
      data[0].retro.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Short/Jogo':
      data[0].shortjogo.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Short/NBA':
      data[0].shortnba.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Short/Treino':
      data[0].shorttreino.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Tenis/Adidas':
      data[0].tenisadidas.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Tenis/Balenciaga':
      data[0].tenisbalenciaga.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Tenis/Gucci':
      data[0].tenisgucci.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Tenis/New Balance':
      data[0].tenisnewbalance.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Tenis/Nike':
      data[0].tenisnike.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Brasileirao':
      data[0].versaojogadorbrasileirao.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Bundesliga':
      data[0].versaojogadorbundesliga.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/La Liga':
      data[0].versaojogadorlaliga.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Liga MX':
      data[0].versaojogadorligamx.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Ligue 1':
      data[0].versaojogadorligue1.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/MLS':
      data[0].versaojogadormls.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Outras Ligas':
      data[0].versaojogadoroutrasligas.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Premier League':
      data[0].versaojogadorpremierleague.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/SAF':
      data[0].versaojogadorsaf.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Selecoes':
      data[0].versaojogadorselecoes.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Jogador/Serie A':
      data[0].versaojogadorseriea.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Brasileirao':
      data[0].versaotorcedorbrasileirao.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Bundesliga':
      data[0].versaotorcedorbundesliga.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/La Liga':
      data[0].versaotorcedorlaliga.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Liga MX':
      data[0].versaotorcedorligamx.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Ligue 1':
      data[0].versaotorcedorligue1.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/MLS':
      data[0].versaotorcedormls.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Outras Ligas':
      data[0].versaotorcedoroutrasligas.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Premier League':
      data[0].versaotorcedorpremierleague.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/SAF':
      data[0].versaotorcedorsaf.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Selecoes':
      data[0].versaotorcedorselecoes.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    case 'Versao Torcedor/Serie A':
      data[0].versaotorcedorseriea.filter(el => {
        if(el.priceSales !== 0)
          setImages.push(el)
      })
      break;
    default:
      break;
  }

  return setImages;
}

export async function sentWhats(_, infos) {

  const {phone, body} = infos

  try {
    // const whats = await axios.post(
    //   'https://api.z-api.io/instances/3B26FB788493D07BC18DA2CB4232B497/token/5ADDD564701A8B4390A21680/send-messages',
    //   {
    //     'phone': phone,
    //     'message': body
    //   }
    // )
    const USER_TOKEN_ID = '152b1118-98e9-445d-8c78-f70c07e830a8'
    const INSTANCE_ID = 'M8YCGY2JRHCRTXWI158JP4N3'
    const INSTANCE_TOKEN = '11be5669-e488-45cf-a336-bdfcd899ae34'

    const GZAPPY_URL = "https://api.gzappy.com/v1/message/send-message"

    const whats = await axios.post(
      GZAPPY_URL,
      {
        'instance_id': INSTANCE_ID,
        'instance_token': INSTANCE_TOKEN,
        'message': [body],
        'phone': phone,
        'req_id': 'fature_catalogo'
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'user_token_id': USER_TOKEN_ID
        }
      }
    )

    console.log('chat', whats)
  } catch (error) {
    console.error(error)
  }
}

export async function sentContact(_, infos) {

  const {phoneClient, phoneStore, name} = infos

  try {
    const whats = await axios.post(
      'https://api.z-api.io/instances/3B26FB788493D07BC18DA2CB4232B497/token/5ADDD564701A8B4390A21680/send-contact',
      {
        "phone": phoneClient,
        "contactName": name,
        "contactPhone": phoneStore
      }
    )

    console.log('contact', whats)
  } catch (error) {
    console.error(error)
  }
}

