<template>
  <Drawer v-if="notIsLogin"/>
  <router-view v-else />
  <!-- <router-view /> -->
</template>

<script>
import Drawer from './components/Drawer.vue'
export default {
  components: {
    Drawer,
  },
  computed: {
    notIsLogin() {
      let route = this.$route.path;

      if(route === '/settings' || route === '/panelADM') {
          return true;
      } else {
          return false;
      }
    },
  },
}
</script>

<style lang="scss">
  @import url('../src/globais/GlobalStyles.scss');
</style>
