<template>
  <q-layout view="hhh lpr lfr">

    <q-header class="navbar">
      <q-toolbar class="bg-verde">
        <q-toolbar-title class="text-white text-center text-bold text-h6">
          {{ showTitle }}
        </q-toolbar-title>
        <q-btn flat class="q-px-xs" color="white" icon="logout" @click="onLogout" />
        <q-btn v-show="isAdmin" flat class="q-px-xs q-mx-md" color="white" icon="fas fa-user-shield" @click="onAdmin" />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { supabase } from '../supabase';

export default {
  data() {
    return {
      miniState: true,
      drawer: false,
      logoPerfil: false,
      qtdPreSale: 0,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    showTitle() {
      let name = this.$route.name;

      return name;
    },
    isAdmin() {
      const { email } = supabase.auth.user()
      
      if(
        email === 'wesleyribeiro123@gmail.com' ||
        email === 'gestaoexcel.oficial@gmail.com'
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onAdmin() {
      if(this.$route.path === '/panelADM') {
        this.$router.push('/settings')
      } else {
        this.$router.push('/panelADM')
      }
    },
    onLogout() {
      localStorage.clear();
      supabase.auth.signOut();

      this.$router.push('/');
    }
  }
}
</script>
